import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import ConfettiExplosion from 'react-confetti-explosion';

import Typography from '@material-ui/core/Typography';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { makeStyles } from '@material-ui/core/styles';
import CustomDialog from 'components/CustomDialog';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    ...theme.subtitle,
    fontWeight: 'bold'
  },
  receiptButton: {},
  loading: {},
  loadingContainer: {
    align: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonContainer: {
    ...theme.buttonContainer,
    justifyContent: 'space-around',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  finishText: {
    textAlign: 'center',
    fontSize: '16px'
  },
  confettiWrapper: {
    position: 'relative'  // Add this wrapper to position the confetti
  },
  confettiContainer: {
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000
  }

}));

function FinishScreen(props) {
  let debug = false;
  const classes = useStyles();
  const [isExploding, setIsExploding] = useState(true);

  const [showingErrors, setShowingErrors] = useState(false);
  const [gettingInvoice, setGettingInvoice] = useState(false);
  
  if (debug) console.log(props);
  
  const { actions, invoice, globalErrors: errors, dialogData, params, section } = props;

  if (debug) console.log('FinishScreen RENDER');

  const handleDialogClose = () => {
    if (errors.length > 0) {
      actions.resetErrors();
      setShowingErrors(false);
    }
    actions.toggleDialog();
  };

  const setErrorDialogInfo = () => {
    actions.setDialoInfo({
      title: 'Page Error',
      body: () => {
        const renderErrors = () => {
          const errorsHtml = [];
          errors.forEach((error, index) => {
            const errorHtml = <li key={`error-${index}`}>{error.message}</li>
            errorsHtml.push(errorHtml);
          });
          return errorsHtml;
        }
        return (
          <ul className={classes.errorList}>
            {renderErrors()}
          </ul>
        )
      }
    });
  }

  const renderButton = (invoice) => {
    if (invoice.receipt_url) {
      return (
        <Button
          variant="contained"
          color="primary"
          target="_blank"
          href={invoice.receipt_url}
          className={classes.receiptButton}
          startIcon={<ReceiptIcon />}
        >
          View Receipt
        </Button>
      )
    }
  }

  const renderContent = () => {
    if (Object.keys(invoice).length === 0) {
      return (
        <div className={classes.loadingContainer}>
          <CircularProgress size={48} className={classes.loading} />
        </div>
      )
    } else {
      if (invoice.status === 'future') {
        return (
          <>
            {isExploding && (
              <div className={classes.confettiWrapper}>
                <div className={classes.confettiContainer}>
                  <ConfettiExplosion 
                    duration={1000} 
                    force={0.8}
                    particleCount={250}
                    width={1600}
                  />
                </div>
              </div>
            )}
            <Typography variant='subtitle1' align="center" className={classes.subtitle}>
              Thank you for resubscribing!
            </Typography>
            <Typography variant='body1' align="center" className={classes.finishText}>
              Your new subscription will activate when your current one runs out. <br /><br /><br />
            </Typography>
          </>
        )
      } else {
        return (
          <>
                {isExploding && (
              <div className={classes.confettiWrapper}>
                <div className={classes.confettiContainer}>
                  <ConfettiExplosion 
                    duration={1000} 
                    force={0.8}
                    particleCount={250}
                    width={1600}
                  />
                </div>
              </div>
            )}
            <Typography variant='subtitle1' align="center" className={classes.subtitle}>
              Thank you for subscribing!
            </Typography>
            <Typography variant='body1' align="center" className={classes.finishText}>
              A confirmation email has been sent to {invoice.customer_email}.
              { section === 'add_subscription'
                ? null
                : " Please click the confirmation link in the email to set your password and finalize your account setup."
              }
            </Typography>
            <div className={classes.buttonContainer}>
              { renderButton(invoice) }
            </div>
          </>
        )
      }
    }
  }

  useEffect(() => {
    if (!gettingInvoice && Object.keys(invoice).length === 0) {
      const { step: invoice_id } = params;
      actions.setStep(4);
      actions.getInvoiceById(invoice_id);
      setGettingInvoice(true);
      return false;
    }

    if (!showingErrors && errors.length > 0) {
      setErrorDialogInfo();
      actions.toggleDialog();
      setShowingErrors(true);
    }
  });

  return (
    <>
      {renderContent()}
      <CustomDialog dialogData={dialogData} handleClose={handleDialogClose} />
    </>
  );
}

FinishScreen.propTypes = {

};

export default FinishScreen;