import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router';
import SignUp from 'components/SignUp';
import Admin from 'components/Admin';
import SavePassword from 'components/SavePassword';
import ConfirmPassword from 'components/ConfirmPassword';
import Info from 'components/MyAccount/Info';
import Subscribe from 'components/MyAccount/Subscribe';

import { ConnectedRouter } from 'connected-react-router';
let debug = false;

function App(props) {

  if (debug) console.log(props);

  const [defaultsSet, setDefaults] = useState(false);
  const { actions, defaultStep, history, promotionalPeriod } = props;

  if (!defaultsSet) {
    actions.loadSection(props.section);

    if (props.products) {
      actions.loadProducts(props.products);
    }

    if (props.assoc_states) {
      actions.loadAssocStates(props.assoc_states);
    }

    if (props.coupons) {
      actions.bootstrapCoupons(props.coupons);
    }

    if (props.defaultStep) {
      actions.bootstrapDefaultStep(parseInt(props.defaultStep));
    }

    if (props.defaultSubscription) {
      actions.bootstrapDefaultSub(props.defaultSubscription);
    }

    if (props.user) {
      actions.bootstrapUser(props.user);
      // if (props.user.coupon_id) actions.loadUserCoupon(props.user.coupon_id);
    }

    if (props.confirmation_token) {
      actions.bootstrapToken(props.confirmation_token);
    }

    if (props.subscription) {
      actions.bootstrapSubscription([props.subscription, props.interval]);
    }

    if (props.personal_message) {
      actions.bootstrapPersonalMessage(props.personal_message);
    }

    if (props.phone_instructions) {
      actions.bootstrapPhoneInstructions(props.phone_instructions);
    }

    if (props.section === 'add_subscription') {
      actions.loadSubscriptionAccountInfo({ userData: props.user, prevProducts: props.previous_products });
    }

    if (props.susbcription_expired) {
      actions.bootstrapSubscriptionExpired(props.susbcription_expired);
    }

    if (props.incomplete_settings) {
      actions.bootstrapIncompleteSettings(props.incomplete_settings);
    }
    
    if (props.future_subscription) {
      actions.bootstrapFutureSubscription(props.future_subscription);
    }

    if (props.states) {
      actions.bootstrapStates(props.states);
    }

    setDefaults(true);
  }

  const renderSectionComponent = () => {
    if (props.section === 'signup') {
      return (
        <Route
          path='/:step?'
          render={(props) => (
            <SignUp {...props} defaultStep={defaultStep} promotionalPeriod={promotionalPeriod}/>
          )}
        />
      )
    } else if (props.section === 'admin') {
      return (
        <Route
          path='/'
          render={(props) => (
            <Admin {...props} />
          )}
        />
      )
    } else if (props.section === 'set_password') {
      return (
        <Route
          path='/'
          render={(props) => (
            <SavePassword {...props} />
          )}
        />
      )
    } else if (props.section === 'confirm_password') {
      return (
        <Route
          path='/'
          render={(props) => (
            <ConfirmPassword {...props} />
          )}
        />
      )
    } else if (props.section === 'edit_account') {
      return (
        <Route
          path='/'
          render={(props) => (
            <Info {...props} />
          )}
        />
      )
    } else if (props.section === 'add_subscription') {
      return (
        <Route
          path='/:step?'
          render={(props) => (
            <Subscribe {...props} defaultStep={defaultStep} />
          )}
        />
      )
    }
  }

  if (debug) console.log('App RENDER');

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {/* { getRedirectRoute() } */}
        { renderSectionComponent() }
      </Switch>
    </ConnectedRouter>
  );
}

App.propTypes = {
  assoc_states: PropTypes.string,
  defaultStep: PropTypes.any,
  history: PropTypes.object.isRequired,
  coupons: PropTypes.string, 
  products: PropTypes.string,
  section: PropTypes.string,
  promotionalPeriod: PropTypes.bool
};

export default App;